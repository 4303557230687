import {SmartComponent} from "smart-component-js";
import FormUtils from "../common/FormUtils";
import LandingUrlBuilder from "../business/LandingUrlBuilder";
import RSVP from "rsvp";
import Utils from "../../common/business/Utils";
import NabaUtils from "../business/NabaUtils";
import CookieManager from "../business/NabaCookieManager";
import NabaCookieManager from "../business/NabaCookieManager";

class LandingComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "LandingComponent";
        super(element, parentComponent, params);

        this.$el = $(element);

        this.isInline = this.$el.is('.inline-landing');

        this.$form = $('form.modal-landing');

        this.formType = this.$form.data('formType');

        this.selectedDegreeId = null;
        this.areaId = this.$form.data('areaId') || null;
        this.forcedCampus = this.$form.data('campusId') || null;
        this.campusId = null;
        this.editionId = null;

        this.areaDegreeStorage = {};

        this.prefilledCourses = {};
        if (!!this.areaId) {
            if (!!this.$form.data('courseAm')) {
                this.prefilledCourses[KD_NABA_SITE_CONSTANTS.DEGREE_LEVELS.POSTGRADUATE_AM] = this.$form.data('courseAm') || null;
            }
            if (!!this.$form.data('courseMoa')) {
                this.prefilledCourses[KD_NABA_SITE_CONSTANTS.DEGREE_LEVELS.POSTGRADUATE_MOA] = this.$form.data('courseMoa') || null;
            }
            if (!!this.$form.data('courseSummer')) {
                this.prefilledCourses[KD_NABA_SITE_CONSTANTS.DEGREE_LEVELS.SUMMER] = this.$form.data('courseSummer') || null;
            }
            if (!!this.$form.data('courseUndergraduate')) {
                this.prefilledCourses[KD_NABA_SITE_CONSTANTS.DEGREE_LEVELS.UNDERGRADUATE] = this.$form.data('courseUndergraduate') || null;
            }
        }

        this.specificCourseId = this.$form.data('specificCourseId') || null;
        this.specificCourseIntake = this.$form.data('specificCourseIntake') || null;

        this.pageCountry = document.geoLocation ? document.geoLocation : 'IT';

        this.$degreeLevel = this.$el.find('[name="Degree_Level__c"]');
        this.$course = this.$form.find('[name="Program_Of_Interest__c"]');
        this.$intake = this.$form.find('[name="School_Term__c"]');
        this.$campus = this.$form.find('[name="CampusLocation__c"]');

        this.$urlBackField = this.$el.find('[name="redirectURL"]');

        this.allowSubmit = false;

        this.init();
    }

    init() {
        if (this.specificCourseId && this.specificCourseIntake) {
            this.$course.val(this.specificCourseId);
            this.$intake.val(this.specificCourseIntake);
        }

        this.$form.find('[type="submit"]').click((e) => {
            if (!FormUtils.validateForm(this)) {
                e.preventDefault();
            }
            if (!FormUtils.validateFields(this.$form.find('input:visible, select:visible'))) {
                e.preventDefault();
                $("html, body").stop().animate({scrollTop: this.$form.find('.has-error:eq(0)').offset().top - 120}, 500, 'swing');
            } else if (!this.allowSubmit) {
                e.preventDefault();
                this.allowSubmit = true;
                FormUtils.showLoader(this.$form);

                FormUtils.doCaptcha(e, this.$el, this.$urlBackField, this.formType, () => {
                    setTimeout(() => {
                        $(e.currentTarget).click()
                    }, 200);

                    if (this.$el.find('[name="Campaign_Source__c"]').val() == '7016N000000QiEVQA0') {
                        //redirect all'url impostato nel campo redirectURL
                    } else {
                        LandingUrlBuilder.updateUrl(this.$el, this.$urlBackField, this.formType);
                    }
                });
            } else {
                if (this.$el.parents('[component="LandingCtrl"][data-result-page]').length > 0) {
                    let resultPage = this.$el.parents('[component="LandingCtrl"][data-result-page]').data('resultPage');
                    if (!!resultPage) {
                        NabaCookieManager.setReplacePageCookie(resultPage);
                    }
                }
            }
        });

        FormUtils.addFormListeners(this.$el);

        this.$form.on('change', '#degree-level-input', (e) => {
            this.areaId = this.$form.data('areaId') || null;
            this.selectedDegreeId = $(e.currentTarget).find('option:selected').data('realId');
            let degree = $(e.currentTarget).find('option:selected').val();
            let label = $(e.currentTarget).find('option:selected').text().toLowerCase();
            this.$form.find('#campus-input').val('');
            this.$form.find('#area-input').val('');
            this.$campus.find('option[value!=""]').remove();

            if (KD_NABA_SITE_CONSTANTS.DEGREE_LEVELS.FOUNDATION_YEAR == degree) {
                this.$form.find('#campus-input').parents('.form-input').hide();
                this.$form.find('#area-input').parents('.form-input').hide();
                this.areaId = null;
                this.loadCourses().then((data) => {
                    for (let i in data) {
                        let res = data[i].result[0];
                        this.campusId = res.idCampus;
                        this.editionId = res.idEdition;
                        this.$course.val(res.idSalesforce);
                        this.$course.data('courseLabel', res.label);
                        this.$campus.val(res.campus);
                        break;
                    }
                });
            } else {
                this.$form.find('#campus-input').parents('.form-input').show();
                this.$form.find('#area-input').parents('.form-input').show();

                if (!!this.areaId) {
                    this.loadCourses();
                } else {
                    this.checkAreas();
                }
            }
        });

        if (!this.areaId) {
            this.$form.on('change', '#area-input', (e) => {
                this.areaId = $(e.currentTarget).find('option:selected').val();

                if (!!this.selectedDegreeId) {
                    this.loadCourses();
                }
            });
        } else {
            this.checkDegrees();
        }

        this.$form.on('change', '#campus-input', (e) => {
            this.campusId = $(e.currentTarget).find('option:selected').data('campusId');
            this.editionId = $(e.currentTarget).find('option:selected').data('editionId');
            this.$course.val($(e.currentTarget).find('option:selected').data('editionSf'));
            this.$course.data('courseLabel', $(e.currentTarget).find('option:selected').data('editionLabel'));

            this.$intake.val($(e.currentTarget).find('option:selected').data('intakeId'));
        });

        if (this.$form.find('#degree-level-input option[data-real-id]').length == 1) {
            this.$form.find('#degree-level-input option[data-real-id]').prop("selected", true);
            this.$form.find('#degree-level-input').change().parents('.form-input__wrap').addClass('disabled');
        }

        if (this.isInline) {
            this.initTelInput();
            this.addlisteners();
        }
    }

    /**
     * Se arrivo in una landing con l'area pre-selezionata, devo controllare quali degree posso mostrare.
     * Normalmente il processo è al contrario, ma qui avendo l'area fissa devo andare a nascondere quei degree che se selezionati
     * in accoppiata con l'area non mostrano risultati.
     *
     * NB: Questo metodo viene eseguito solamente all'init.
     */
    checkDegrees() {
        if (!!this.areaId) {
            FormUtils.showLoader(this.$form);
            let allPromises = [];
            this.$form.find('#degree-level-input option').each((i, el) => {
                let $opt = $(el);
                if (!!$opt.data('realId')) {
                    let promise = this.cachedCoursesCall(this.areaId, $opt.data('realId'));
                    promise.then((data) => {
                        if (Object.keys(data).length <= 0) {
                            $opt.addClass('disabled');
                        }
                    });
                    allPromises.push(promise);
                }
            });

            RSVP.all(allPromises).then(() => {
                FormUtils.hideLoader(this.$form);
            });
        }
    }

    /**
     *         Per evitare di mostrare aree vuote nella tendina, quando scelgo un degreelevel (ma non prima) devo controllare se le aree hanno degli intake.
     *         Per farlo sono costretto a fare una chiamata per ogni area, è brutto, ma almeno mi salvo il risultato in modo da non doverla rifare.
     */
    checkAreas() {
        if (!!this.selectedDegreeId) {
            FormUtils.showLoader(this.$form);
            let allPromises = [];
            this.$form.find('#area-input option').removeClass('disabled').each((i, el) => {
                let $opt = $(el);
                let forcedArea = $opt.attr('value');
                if (!!forcedArea) {
                    let promise = this.cachedCoursesCall(forcedArea);
                    promise.then((data) => {
                        if (Object.keys(data).length <= 0) {
                            $opt.addClass('disabled');
                        }
                    });
                    allPromises.push(promise);
                }
            });

            RSVP.all(allPromises).then(() => {
                FormUtils.hideLoader(this.$form);
            })
        } else {
            this.$form.find('#area-input option').addClass('disabled');
        }
    }

    cachedCoursesCall(forcedArea, forcedDegree) {
        return new RSVP.Promise((resolve, reject) => {
            let areaId = this.areaId;
            if (forcedArea != null && (forcedArea.length > 0 || forcedArea > 0)) {
                areaId = forcedArea;
            }
            let selectedDegreeId = this.selectedDegreeId;
            if (!!forcedDegree) {
                selectedDegreeId = forcedDegree;
            }
            if (!this.areaDegreeStorage[selectedDegreeId]) {
                this.areaDegreeStorage[selectedDegreeId] = {};
            }
            if (!this.areaDegreeStorage[selectedDegreeId][areaId]) {
                let forcedCourse = null;
                if (!!this.prefilledCourses[selectedDegreeId]) {
                    forcedCourse = this.prefilledCourses[selectedDegreeId];
                }

                FormUtils.loadCourses(this.formType, selectedDegreeId, areaId, forcedCourse).then((data) => {
                    //Se ho selezionato di mostrare solamente i corsi di uno specifico campus, i corsi degli altri
                    //campus è come se non esistessero.
                    if (!!this.forcedCampus) {
                        for (let area in data) {
                            if (data[area].result.length > 0) {
                                for (let i in data[area].result) {
                                    if (this.forcedCampus != data[area].result[i].idCampus) {
                                        data[area].result.splice(i, 1);
                                    }
                                }
                                if (data[area].result.length == 0) {
                                    delete data[area];
                                }
                            }
                        }
                    }

                    this.areaDegreeStorage[selectedDegreeId][areaId] = data;
                    resolve(data);
                });
            } else {
                resolve(this.areaDegreeStorage[selectedDegreeId][areaId]);
            }
        });
    }

    loadCourses() {
        FormUtils.showLoader(this.$form);

        this.$course.val('');
        this.$course.data('courseLabel', null);
        this.$intake.val('');
        this.$campus.val('');

        return new RSVP.Promise((resolve, reject) => {
            return this.cachedCoursesCall().then((courseResp) => {
                this.$campus.find('option').remove();
                this.$campus.append(KD_NABA_PAGE_CONSTANTS.EMPTY_CAMPUS_OPTION);
                let intakePromises = [];

                for (let area in courseResp) {
                    if (courseResp[area].result.length > 0) {
                        for (let i in courseResp[area].result) {
                            let record = courseResp[area].result[i];

                            //Per ogni campus carico il suo primo intake e lo aggiungo come attributo e come label alla option
                            intakePromises.push(this.loadIntake(record.idCampus, record.idEdition).then((intakeResp) => {
                                if (!!intakeResp && intakeResp.length > 0) {
                                    let intake = intakeResp[0];
                                    this.$campus.append(`
                                        <option 
                                            value="${record.campus}" 
                                            data-campus-id="${record.idCampus}"
                                            data-edition-id="${record.idEdition}"
                                            data-edition-sf="${record.idSalesforce}"
                                            data-edition-label="${record.label}"
                                            data-intake-id="${intake.idsalesforce}"
                                            data-intake-position="${intake.position}"
                                        >${record.campusLabel} - ${intake.label}</option>
                                    `);
                                    if (this.selectedDegreeId != KD_NABA_SITE_CONSTANTS.DEGREE_LEVELS.SUMMER) {
                                        if (intakeResp.length > 1) {
                                            intake = intakeResp[1];
                                            this.$campus.append(`
                                            <option 
                                                value="${record.campus}" 
                                                data-campus-id="${record.idCampus}"
                                                data-edition-id="${record.idEdition}"
                                                data-edition-sf="${record.idSalesforce}"
                                                data-edition-label="${record.label}"
                                                data-intake-id="${intake.idsalesforce}"
                                                data-intake-position="${intake.position}"
                                            >${record.campusLabel} - ${intake.label}</option>
                                            `);
                                            if (intakeResp.length > 2) {
                                                intake = intakeResp[2];
                                                this.$campus.append(`
                                                <option 
                                                    value="${record.campus}" 
                                                    data-campus-id="${record.idCampus}"
                                                    data-edition-id="${record.idEdition}"
                                                    data-edition-sf="${record.idSalesforce}"
                                                    data-edition-label="${record.label}"
                                                    data-intake-id="${intake.idsalesforce}"
                                                    data-intake-position="${intake.position}"
                                                >${record.campusLabel} - ${intake.label}</option>
                                            `);
                                            }
                                        }
                                    }
                                }
                            }));
                        }
                    }
                }

                RSVP.all(intakePromises).then(() => {
                    this.sortIntakes();

                    this.campusId = this.$campus.find('option:selected').data('campusId');
                    this.editionId = this.$campus.find('option:selected').data('editionId');
                    this.$course.val(this.$campus.find('option:selected').data('editionSf'));
                    this.$course.data('courseLabel', this.$campus.find('option:selected').data('editionLabel'));

                    this.$intake.val(this.$campus.find('option:selected').data('intakeId'));

                    FormUtils.hideLoader(this.$form);
                    resolve(courseResp);
                })
            });
        });
    }

    loadIntake(campusId, editionId) {
        return FormUtils.loadIntake(campusId, this.selectedDegreeId, this.formType, editionId);
    }

    updatePhone() {
        let number = this.$form.find('[name="phone_prefix"]').val();
        number += this.$form.find('[name="phone_number"]').val();
        this.$form.find('[name="MobilePhone"]').val(number);
    }

    initTelInput() {
        this.countryInput = this.$form[0].querySelector('[data-js-name="country"]');
        this.prefixInput = this.$form[0].querySelector('[data-js-name="phone_prefix"]');
        this.phoneInput = this.$form[0].querySelector('[data-js-name="phone_number"]');
        this.phoneLabel = this.$form[0].querySelector(
            ".form-input__phone .form-input__label"
        );
        if (this.phoneInput.value) {
            this.phoneLabel.classList.add("selected");
        }
        this.instanceIntlTelInput();
    }

    instanceIntlTelInput() {
        this.intlTelInput = intlTelInput(this.phoneInput, {
            initialCountry: this.pageCountry,
            preferredCountries: ['it'],
            separateDialCode: true
        });

        this.setCountry();
    }

    setCountry() {
        let countryId = this.intlTelInput
            .getSelectedCountryData()
            .iso2.toUpperCase();
        let countryCode = this.intlTelInput.getSelectedCountryData().dialCode;
        this.countryInput.value = countryId;
        this.prefixInput.value = "+" + countryCode;
    }

    addlisteners() {
        this.phoneInput.addEventListener("countrychange", () => {
            this.setCountry();
        });
        this.phoneInput.addEventListener("keyup", () => {
            this.setCountry();
        });
        this.phoneInput.addEventListener("focus", () => {
            this.phoneLabel.classList.add("selected");
        });
        this.phoneInput.addEventListener("blur", () => {
            if (this.phoneInput.value.length === 0) {
                this.phoneLabel.classList.remove("selected");
            }
        });
    }

    // sortIntakes() {
    //     let optMap = {};
    //     this.$campus.find('option').each((i, el) => {
    //         let $opt = $(el);
    //         let intake = $opt.data('intakeId');
    //         let campus = $opt.text().split(' - ')[0];
    //         if (!optMap[intake]) {
    //             optMap[intake] = {};
    //         }
    //         optMap[intake][campus] = $opt.detach();
    //     })
    //     let intakeKeys = Object.keys(optMap).sort((a, b) => {
    //         if (a == b) {
    //             return 0
    //         }
    //         if (a == null) {
    //             return -1
    //         }
    //         if (b == null) {
    //             return 1;
    //         }
    //
    //         let aSplit = a.split('-');
    //         let bSplit = b.split('-');
    //         if (parseInt(aSplit[1]) == parseInt(bSplit[1])) {
    //             if (parseInt(aSplit[0]) == parseInt(bSplit[0])) {
    //                 return 0;
    //             } else {
    //                 return parseInt(aSplit[0]) > parseInt(bSplit[0]) ? 1 : -1;
    //             }
    //         } else {
    //             return parseInt(aSplit[1]) > parseInt(bSplit[1]) ? 1 : -1;
    //         }
    //     });
    //     for (let i in intakeKeys) {
    //         let campusKeys = Object.keys(optMap[intakeKeys[i]]).sort();
    //         for (let j in campusKeys) {
    //             this.$campus.append(optMap[intakeKeys[i]][campusKeys[j]]);
    //         }
    //     }
    // }
    sortIntakes() {
        let optMap = {};
        this.$campus.find('option').each((i, el) => {
            let $opt = $(el);
            let position = parseInt($opt.data('intakePosition'));
            if (!optMap[position]) {
                optMap[position] = [];
            }
            optMap[position].push($opt.detach());
        })
        let intakeKeys = Object.keys(optMap).sort();
        for (let i in intakeKeys) {
            this.$campus.append(optMap[intakeKeys[i]]);
        }

        this.$campus.val(this.$campus.find('option:eq(0)').attr('value')).change()
    }
}

export default LandingComponent;